import React from "react";
import GaugeChart from "react-gauge-chart";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { ReactComponent as Arrow } from "../assets/svg/arrow.svg";
// import { Zugi } from "./common/Zugi";



export const ChecinkgResult = ({ answers, returnPage }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryParams = new URLSearchParams(document.location.search);
  queryParams.append('criteria', answers);

  const answersClicked = (answers) => {
    //1) 6000 unChecked
    if (answers.includes("Marketing_question") == false) return 0.1;
    //2) only 6000 checked
    else if (answers.includes("Marketing_question") && answers.length == 1)
      return 0.3;
    //3) only 6000 + 1 other coice but Insurance / donations
    else if (
      answers.includes("Marketing_question") &&
      answers.includes("privateInsurance") == false &&
      answers.includes("donations") == false &&
      answers.length == 2
    )
      return 0.3;
    //4) only 6000 + Insurance / donations checked
    else if (
      answers.includes("Marketing_question") &&
      (answers.includes("privateInsurance") || answers.includes("donations")) &&
      answers.length == 2
    )
      return 0.5;
    //5) only 6000 + 2 other coice but Insurance / donations- todo
    else if (
      answers.includes("Marketing_question") &&
      answers.includes("privateInsurance") == false &&
      answers.includes("donations") == false &&
      answers.length == 3
    )
      return 0.7;
    //6) 6000 + Insurance / donations checked + any other choice
    else if (
      answers.includes("Marketing_question") &&
      (answers.includes("privateInsurance") || answers.includes("donations")) &&
      answers.length == 3
    )
      return 0.7;
    //7)
    else return 0.97;
  };

  const refundProbability = [
    "סיכוי נמוך מאוד להחזר מס",
    "סיכוי נמוך להחזר מס",
    "סיכוי בינוני להחזר מס",
    "סיכוי גבוה להחזר מס!",
    "סיכוי גבוה מאוד להחזר מס!",
  ];

  const refundProbabilityHandler = (refundProbability) => {
    if (answersClicked(answers) === 0.1) return refundProbability[0];
    else if (answersClicked(answers) === 0.3) return refundProbability[1];
    else if (answersClicked(answers) === 0.5) return refundProbability[2];
    else if (answersClicked(answers) === 0.7) return refundProbability[3];
    else if (answersClicked(answers) === 0.97) return refundProbability[4];
  };

  const chanceToRefuond = [
    `מניתוח הנתונים שלך עולה כי ככל הנראה לא מגיע לך החזר כספי מרשות המיסים.`,

    `מניתוח הנתונים שלך עולה כי כנראה לא מגיע לך החזר כספי מרשות המיסים.`,

    `מניתוח הנתונים שלך עולה כי ייתכן שמגיע לך החזר כספי מרשות המיסים.`,

    `מניתוח הנתונים שלך עולה כי כנראה מגיע לך החזר כספי מרשות המיסים.`,

    `מהנתונים שלך עולה כי ככל הנראה מגיע לך החזר כספי מרשות המיסים.`,
  ];
  const chanceToRefuondHandler = (chanceToRefuond) => {
    if (answersClicked(answers) === 0.1) return chanceToRefuond[0];
    else if (answersClicked(answers) === 0.3) return chanceToRefuond[1];
    else if (answersClicked(answers) === 0.5) return chanceToRefuond[2];
    else if (answersClicked(answers) === 0.7) return chanceToRefuond[3];
    else if (answersClicked(answers) === 0.97) return chanceToRefuond[4];
  };
  const recommandation = [
    <p> לוותר על הבדיקה. אנחנו מזמינים אותך למלא שוב את השאלון בשנה הבאה,  כדי להבטיח שאם יגיע לך החזר מס, הוא יועבר לחשבון הבנק שלך מהר ובקלות.</p>,
    <p>ניתן לבצע בדיקת זכאות בחינם, אך הסיכוי לקבלת החזר מס<b>  עבור 6 השנים האחרונות</b> נמוך יחסית.</p>,
    <p>לבצע בדיקת זכאות בחינם וללא כל התחייבות, ולקבל תשובה מדויקת אם וכמה כסף מגיע לך <b>עבור כל אחת מ-6 השנים האחרונות</b>.</p>,
    <p>לבצע בדיקת זכאות בחינם וללא כל התחייבות,  ולקבל תשובה מדויקת אם וכמה כסף מגיע לך <b>עבור כל אחת מ-6 השנים האחרונות</b>.</p>,
    <p>לבצע בדיקת זכאות בחינם וללא כל התחייבות,  ולקבל תשובה מדויקת אם וכמה כסף מגיע לך <b>עבור כל אחת מ-6 השנים האחרונות</b>.</p>,
  ];
  const recommandationHandler = (recommandation) => {
    if (answersClicked(answers) === 0.1) return recommandation[0];
    else if (answersClicked(answers) === 0.3) return recommandation[1];
    else if (answersClicked(answers) === 0.5) return recommandation[2];
    else if (answersClicked(answers) === 0.7) return recommandation[3];
    else if (answersClicked(answers) === 0.97) return recommandation[4];
  };

  return (
    <div className="second-page-body main-container">
      <h2 className="check-resoult">תוצאות :</h2>
      <h1 className="refund-probability">
        {refundProbabilityHandler(refundProbability)}
      </h1>
      <div className="gauge-wraper">
        <GaugeChart
          className="gauge-chart1"
          id="gauge-chart1"
          // style={{height:170}}
          nrOfLevels={5}
          percent={answersClicked(answers)}
          arcWidth={0.4}
          hideText={true}
          animDelay={650}
          animateDuration={7500}
          colors={["#ff0000", "#ffa500", "#f3f32b", " #0ae93a", "#11bd36"]}
        />
      </div>
      <p className="chance-to-refuond">
        {chanceToRefuondHandler(chanceToRefuond) + ' '}
        מעל 60% מהשכירים שכבר ביצעו את הבדיקה זכאים להחזר מס ממוצע של כ-7,500 ש"ח למשפחה.
      </p>
      <br />
      <div>
        <span className="our-recommandation"> ההמלצה שלנו: </span>
        <p className="recommandation">
          {recommandationHandler(recommandation)}
        </p>
        {/* <Zugi/> */}
        <div className="end-buttons-wraper">
          <a
            className="free-checking" id="reg-end"
            href={"https://my.taxon.co.il?" + decodeURIComponent(queryParams)}
            target="_blank"
            rel="noreferrer"
          >
            להמשך בדיקה בחינם
          </a>
          <br></br>
          <button className="back-to-main" onClick={returnPage}>
            <Arrow className="arrow" />
            בחזרה לשאלון
          </button>
        </div>
      </div>
    </div>
  );
};

