import React, { useEffect, useState } from "react";
// import { ReactComponent as QuestionMark } from "../assets/svg/questionMark.svg";
import { ReactComponent as BoldQuestion } from "../assets/svg/boldQuestion.svg";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { Zugi } from "./common/Zugi";

export const MainPage = ({ nextPage }) => {
  const [questions, setQuestions] = useState([]);
  const [zugiTxt, setZugiTxt] = useState(null);
  useEffect(() => {
    loadQuestions();
    return () => { };
  }, []);

  const loadQuestions = async () => {
    try {
      const res = await fetch("https://admin.taxon.co.il/cms-questionnaire");
      const resJSON = await res.json();
      const questions = resJSON.reduce((acc, quest) => {
        const data = {
          name: quest.varname,
          txt: quest.button_title,
          moreInfo: quest.button_subtitle,
          zugiText: quest.body_text,
        };
        return { ...acc, [quest.varname]: data };
      }, {});

      // console.log("questions", questions);
      setQuestions(questions);
      //console.log(await res.json());
    } catch (err) { }
  };

  const setZugi = (ev, txt) => {
    ev.stopPropagation();
    setZugiTxt("null");
    setTimeout(() => setZugiTxt(txt));
  };

  const submit = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.target);
    const answers = Object.keys(Object.fromEntries(formData.entries()));
    nextPage(answers);
  };

  let queryString = new URLSearchParams(window.location.search);
  let hideNav = queryString.get('hideNav');

  return (
    <div>
      <div className="main-container">
        {!hideNav &&
          <div className="introText">
            <h1 className="main-title">האם מגיע לך החזר מס?</h1>
            <p className="first-paragraph">
              שאלון קצר זה יקבע מה הסיכוי שמגיע לך החזר כספי מרשות המיסים.
              עליך לסמן כל משפט שנכון לגביך ב-6 השנים האחרונות, ואז ללחוץ על הכפתור בתחתית המסך, כדי לקבל תשובה מיידית.
              זה לוקח כמה שניות. שנתחיל?
            </p>
          </div>
        }
        <form onSubmit={submit}>
          <div className="card-container">
            {Object.keys(questions).map((key, idx) => {
              const question = questions[key];
              return (
                <div className="relative question-card-warpper" key={idx}>
                  <div
                    className="question-mark"
                    onClick={(ev) => setZugi(ev, question.zugiText)}
                  >
                    <BoldQuestion className="bold-question" />
                  </div>
                  {question.name === "Marketing_question" ?
                    <input
                      name={question.name}
                      type="checkbox"
                      className="checked"
                      defaultChecked={true}
                      id={question.name}
                      hidden
                    />
                    :
                    <input
                      name={question.name}
                      type="checkbox"
                      className="checked"
                      id={question.name}
                      hidden
                    />
                  }
                  <label className="question-card" htmlFor={question.name}>
                    <div>
                      <p className="question-txt">{question.txt}</p>
                      <p className="question-more-info"> {question.moreInfo}</p>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>

          <button className="ending-button">
            חישוב הסיכוי להחזר מס
          </button>
        </form>
      </div>
      <Zugi prevText={zugiTxt} />
    </div>
  );
};
