import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as MainZugi } from "../../assets/svg/main-zugi.svg";
import { ReactComponent as ZugiX } from "../../assets/svg/zugi-x-mark.svg";

export const Zugi = ({ fullText, prevText }) => {
  const [readMore, setReadMore] = useState(false);
  const [isOpenText, setIsOpenText] = useState(true);

  useEffect(() => {
    setIsOpenText(true);
    // console.log(prevText);
    return () => {};
  }, [prevText]);

  const resetZugi = () => {
    setReadMore(false);
    setIsOpenText(true);
  };

  const closeText = (ev) => {
    ev?.stopPropagation();
    setIsOpenText(false);
  };

  const openText = (ev) => {
    ev.stopPropagation();
    setIsOpenText(true);
    if (fullText) setReadMore(true);
  };

  const openReadMore = () => {
    setReadMore(true);
  };

  const ZugiIcon = (props) => {
    return <MainZugi {...props} />;
  };

  if (!fullText && !prevText) return <div></div>;

  return (
    <div className="zugi-container pointer" id="zugi">
      {isOpenText && (
        <div
          className="zugi-bubble-text"
          onClick={() => {
            if (fullText) setReadMore((prevVal) => !prevVal);
          }}
        >
          <span className="x-mark" onClick={closeText}>
            <ZugiX />
          </span>
          {readMore ? (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: fullText }}
            />
          ) : (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: prevText }}
            />
          )}
          {/* <div className="content" >{readMore ? fullText : prevText}</div> */}
        </div>
      )}

      <div className="zugi" onClick={openText}>
        <ZugiIcon />
      </div>
    </div>
  );
};
