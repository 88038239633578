import { getValue } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "./assets/svg/logo.svg";
import { ChecinkgResult } from "./components/ChecinkgResult";
import { MainPage } from "./components/MainPage";

function App() {
  const [isNextPage, setIsNextPage] = useState(false);
  const [answers, setAnswers] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isNextPage]);

  // if (isNextPage) return <ChecinkgResult />;
  const nextPage = (ans) => {
    setAnswers(ans);
    setIsNextPage(true);
  };

  const returnPage = () =>  setIsNextPage(false);

  let queryString = new URLSearchParams(window.location.search);
  let hideNav = queryString.get('hideNav');

  return (
    <div>
      {!hideNav &&
        <header className="page-header .hide-wp">
          <div className="header-wraper main-container">
            {/* <h2 className="header-title "> שאלון זכאות להחזר מס</h2> */}
            <Logo className="taxon-logo" />
          </div>
        </header>
      }
      {isNextPage ? (
        <ChecinkgResult answers={answers} returnPage={returnPage} />
      ) : (
        <MainPage nextPage={nextPage} />
      )}
    </div>
  );
}

export default App;
